import { graphql } from "gatsby";
import React from "react";
import { ExternalSitePath } from "src/constant";
import { Member } from "src/interfaces";
import styled from "styled-components";
import { generateMedia } from "styled-media-query";
import { ImageTag } from "src/helper/image-helper";
import Header from "src/components/headers/header";
import "modern-normalize";
import "../styles/normalize";

const mediaMobile = generateMedia({
  tablet: "1000px"
}).lessThan("tablet");

const mediaDesktop = generateMedia({
  tablet: "1000px"
}).greaterThan("tablet");

export interface ProductAuthorTemplateProps {
  sp_banner_img: string;
  pc_banner_img: string;
  first_authors: Member[];
  second_authors: Member[];
  first_label: string;
  first_enable: boolean;
  second_enable: boolean;
  second_label: string;
  product_type: string;
  product_title: string;
  event_title: string;
  pageurl: string;
  is_preview?: boolean;
}

export class ProductAuthorTemplate extends React.Component<ProductAuthorTemplateProps> {
  public render() {
    const props = this.props;
    return (
      <Wrapper>
        <div style={{ width: "100%" }}>
          <Header
            past_infomations={[]}
            categories={[]}
            simple={true}
            title={props.event_title}
            logo_image={"/images/pixiv-white.svg"}
            pageurl={props.pageurl}
          ></Header>
        </div>
        <SpOnly>
          {props.sp_banner_img && (
            <BannerImage style={{ width: "100vw" }} src={props.sp_banner_img} isPreview={props.is_preview}></BannerImage>
          )}
        </SpOnly>
        <PcOnly>
          {props.pc_banner_img && (
            <BannerImage style={{ width: "100vw" }} src={props.pc_banner_img} isPreview={props.is_preview}></BannerImage>
          )}
        </PcOnly>
        <ProductType>{props.product_type}</ProductType>
        <Title>
          <div dangerouslySetInnerHTML={{ __html: props.product_title }}></div>
        </Title>
        {props.first_enable && <ProductInfoLabelBoxDark>{props.first_label}</ProductInfoLabelBoxDark>}
        {props.first_enable && (
          <AuthorContainer>
            {this.props.first_authors.map(data => (
              <div>
                <SpOnly>{this.renderMemberMobile(data)}</SpOnly>
                <PcOnly>{this.renderMember(data)}</PcOnly>
              </div>
            ))}
          </AuthorContainer>
        )}
        {props.second_enable && <ProductInfoLabelBoxDark>{props.second_label}</ProductInfoLabelBoxDark>}
        {props.second_enable && (
          <MemberContainer>
            {this.props.second_authors.map(data => (
              <div>
                <SpOnly>{this.renderMemberMobile(data)}</SpOnly>
                <PcOnly>{this.renderMember(data)}</PcOnly>
              </div>
            ))}
          </MemberContainer>
        )}
      </Wrapper>
    );
  }

  private renderMember(member: Member) {
    return (
      <MemberBlockContainer key={member.id}>
        <MemberImage src={`${member.icon}`}></MemberImage>
        <MemberTextContainer>
          <MemberName target="_blank" href={ExternalSitePath.pixiv_userpage_base + member.id}>
            {member.name}
          </MemberName>
        </MemberTextContainer>
      </MemberBlockContainer>
    );
  }

  private renderMemberMobile(member: Member) {
    return (
      <MemberBlockContainerMobile key={member.id}>
        <MemberImageMobile src={`${member.icon}`}></MemberImageMobile>
        <MemberTextContainerMobile>
          <MemberName target="_blank" href={ExternalSitePath.pixiv_userpage_base + member.id}>
            {member.name}
          </MemberName>
        </MemberTextContainerMobile>
      </MemberBlockContainerMobile>
    );
  }
}

const PcOnly = styled.div`
  ${mediaMobile`
    display: none;
  `}

  ${mediaDesktop`
    display:block;
  `}
`;

const SpOnly = styled.div`
  ${mediaMobile`
    display: block;
  `}
  ${mediaDesktop`
    display:none;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
`;

const BannerImage = styled(ImageTag)`
  width: 100vw;
`;
const ProductType = styled.div`
  font-size: 16px;
  line-height: 34px;
  margin-top: 56px;
  ${mediaMobile`
    display:none;
  `}
`;

const Title = styled.div`
  font-size: 32px;
  line-height: 40px;

  /* or 150% */

  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  margin-top: 8px;
  ${mediaMobile`
    margin-top: 56px;
    flex-direction: column;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  `}
`;

const MemberContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 95%;
  grid-template-columns: repeat(auto-fit, 232px);
  ${mediaMobile`
    grid-template-columns: repeat(auto-fit, 152px);
  `}
`;

const AuthorContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 95%;
  grid-template-columns: repeat(auto-fit, 232px);
  ${mediaMobile`
    grid-template-columns: repeat(auto-fit, 152px);
  `}
`;

const MemberBlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 272px;
  padding: 16px;
`;

const MemberBlockContainerMobile = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 172px;
  padding: 8px;
`;

const MemberImage = styled.img`
  width: 88px;
  height: 88px;
  object-fit: cover;
  border-radius: 50%;
`;

const MemberImageMobile = styled.img`
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 50%;
`;

const MemberTextContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 80px;
  margin-left: 16px;
`;

const MemberTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 132px;
  margin-left: 8px;
`;

const MemberName = styled.a`
  font-size: 12px;
  line-height: 16px;
  color: #0096fa;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ProductInfoLabelBoxDark = styled.div`
  width: 126px;
  height: 24px;
  font-size: 12px;
  line-height: 34px;
  font-weight: bold;
  background-color: #6c6c6c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 56px;

  ${mediaMobile`
    margin-top: 24px;
    margin-bottom: 20px;
  `}
`;

interface ProductAuthorProps {
  data: {
    allMarkdownRemark: {
      edges: Array<{
        node: {
          frontmatter: {
            name: string;
            pageurl: string;
          };
        };
      }>;
    };
    markdownRemark: {
      id: string;
      frontmatter: {
        name: string;
        kind: string;
        author_list: {
          banner_image: string;
          banner_image_sp: string;
          author_list_first: {
            name: string;
            enabled: boolean;
            pixiv_member: Array<{
              id: string;
              name: string;
              icon: string;
              description: string;
            }>;
          };
          author_list_second: {
            name: string;
            enabled: boolean;
            pixiv_member: Array<{
              id: string;
              name: string;
              icon: string;
              description: string;
            }>;
          };
        };
      };
    };
  };
}

const ProductAuthorPage: React.SFC<ProductAuthorProps> = ({ data }) => {
  const productAuthorTemplateProps: ProductAuthorTemplateProps = {
    product_title: data.markdownRemark.frontmatter.name,
    product_type: data.markdownRemark.frontmatter.kind,
    first_authors:
      data.markdownRemark.frontmatter.author_list &&
      data.markdownRemark.frontmatter.author_list.author_list_first &&
      data.markdownRemark.frontmatter.author_list.author_list_first.pixiv_member
        ? data.markdownRemark.frontmatter.author_list.author_list_first.pixiv_member.map(value => value)
        : [],
    second_authors:
      data.markdownRemark.frontmatter.author_list &&
      data.markdownRemark.frontmatter.author_list.author_list_second &&
      data.markdownRemark.frontmatter.author_list.author_list_second.pixiv_member
        ? data.markdownRemark.frontmatter.author_list.author_list_second.pixiv_member.map(value => value)
        : [],
    sp_banner_img: data.markdownRemark.frontmatter.author_list ? data.markdownRemark.frontmatter.author_list.banner_image_sp : "",
    pc_banner_img: data.markdownRemark.frontmatter.author_list ? data.markdownRemark.frontmatter.author_list.banner_image : "",
    first_label:
      data.markdownRemark.frontmatter.author_list && data.markdownRemark.frontmatter.author_list.author_list_first
        ? data.markdownRemark.frontmatter.author_list.author_list_first.name
        : "表紙イラスト",
    second_label:
      data.markdownRemark.frontmatter.author_list && data.markdownRemark.frontmatter.author_list.author_list_second
        ? data.markdownRemark.frontmatter.author_list.author_list_second.name
        : "掲載作家一覧",
    event_title: data.allMarkdownRemark.edges[0].node.frontmatter.name,
    pageurl: data.allMarkdownRemark.edges[0].node.frontmatter.pageurl,
    first_enable:
      data.markdownRemark.frontmatter.author_list && data.markdownRemark.frontmatter.author_list.author_list_first
        ? data.markdownRemark.frontmatter.author_list.author_list_first.enabled
        : false,
    second_enable:
      data.markdownRemark.frontmatter.author_list && data.markdownRemark.frontmatter.author_list.author_list_first
        ? data.markdownRemark.frontmatter.author_list.author_list_second.enabled
        : false
  };
  return <ProductAuthorTemplate {...productAuthorTemplateProps}></ProductAuthorTemplate>;
};

export default ProductAuthorPage;

export const ProductAuthorQuery = graphql`
  query ProductAuthorQuery($id: String!, $node_id: String!) {
    allMarkdownRemark(filter: { id: { eq: $node_id } }) {
      edges {
        node {
          frontmatter {
            name
            pageurl
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        name
        kind
        author_list {
          is_author_list
          banner_image
          banner_image_sp
          author_list_first {
            name
            enabled
            pixiv_member {
              name
              description
              icon
              id
            }
          }
          author_list_second {
            name
            enabled
            pixiv_member {
              name
              description
              icon
              id
            }
          }
        }
      }
    }
  }
`;
